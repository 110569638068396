<template>
  <div class="zone">
    <TJDetailTitle :titleData="'基本信息'" />
    <TJDetailMenu :dataList="dataList" />

    <TJDetailTitle :titleData="'检测信息'" />
    <TJDetailMenu :dataList="dataListB" />

    <span v-if="detailData.c1_pdf||detailData.c2_pdf">PDF加载可能较慢,请等待...</span>
    <span v-else>暂无报告单</span>
    <div class="printBox">
      <embed 
        id="printMe"
        v-if="detailData.c1_pdf"
        :src="detailData.c1_pdf"
        type="application/pdf"
        width="100%"
        height="800px" />
      <embed 
        id="printMe"
        v-if="detailData.c2_pdf"
        :src="detailData.c2_pdf"
        type="application/pdf"
        width="100%"
        height="800px" />
    </div>


    <!-- <div class="bottomBtn">
      <p @click="openFile(detailData.c1_pdf)" v-if="detailData.c1_pdf">查看C1报告</p>
      <p @click="openFile(detailData.c2_pdf)" v-if="detailData.c2_pdf">查看C2报告</p>
      <p @click="openFile(detailData.c3_pdf)" v-if="detailData.c3_pdf">查看C3报告</p>
    </div> -->
  </div>
</template>

<script>
import apiDetail from '@/api/searchDetail.js'
import TJDetailTitle from '@/components/TJDetailTitle'
import TJDetailMenu from '@/components/TJDetailMenu'
export default {
  name: 'ControlDetail',
  components: { TJDetailTitle, TJDetailMenu },

  data() {
    return {
      detailId: '',
      detailData: {},
      dataListBShow: false,

      dataList: [
        {
          name: '医院',
          value: '',
          width: '33%',
        },
        {
          name: '检测项目',
          value: '',
          width: '33%',
        },
        {
          name: '平台',
          value: '',
          width: '33%',
          rightBorder: false,
        },
        {
          name: '质控批号',
          value: '',
          width: '33%',
        },
        {
          name: '试剂批号',
          value: '',
          width: '33%',
        },
        {
          name: '质控品开瓶日期',
          value: '',
          width: '33%',
          rightBorder: false,
        },
        {
          name: '质控品最新使用日期',
          value: '',
          width: '33%',
          bottomBorder: false,
        },
        {
          name: '质控品开瓶时长',
          value: '',
          width: '33%',
          bottomBorder: false,
        },
        {
          name: '质控状态',
          value: '',
          width: '33%',
          bottomBorder: false,
          rightBorder: false,
        },
      ],

      dataListB: [

      ],
    }
  },
  created() {
    this.detailId = this.$route.query.id
    this.qc_pointFun()
  },
  methods: {
    // 查看失控报告单
    openFile(url) {
      var a = document.createElement('a')
        window.open(url, "_blank");
    },
    qc_pointFun() {
      apiDetail
        .qc_point({ serum_lc_id: this.detailId,})
        .then((res) => {
          this.detailData = res.data

          this.dataList[0].value = this.detailData.hospital_full_name
          this.dataList[1].value = this.detailData.testing_unique
          this.dataList[2].value = this.detailData.platform_unique
          this.dataList[3].value = this.detailData.qc_batch 
          this.dataList[4].value = this.detailData.reagent_batch 
          this.dataList[5].value = this.detailData.open_date
          this.dataList[6].value = this.detailData.last_used_date
          this.dataList[7].value = this.detailData.length_time
          this.dataList[8].value = this.detailData.qc_status //质控状态

          let qc_data = this.detailData.qc_data;
          let qc_rule = this.detailData.qc_rule;
          let C1 = [
            { 
              name: 'C1_均值',
              value: qc_data.target_c1.toFixed(2),
              width: '33%',
            },
            {
              name: 'C1_SD',
              value: qc_data.sd_c1.toFixed(2),
              width: '33%',
            },
            {
              name: 'C1_CV',
              value: (qc_data.cv_c1*100).toFixed(2)+'%',
              width: '33%',
              rightBorder: false,
            },
            {
              name: 'C1质控结果',
              value: this.detailData.is_c1_lc?'失控':'在控',
              width: '33%',
            },
            {
              name: 'C1违背规则',
              value: this.detailData.c1_rule,
              width: '66%',
              rightBorder: false,
            },
            {
              name: 'C1_差值吸光度',
              value: this.detailData.c1_abs_info.abs_diff,
              width: '25%',
            },
            {
              name: 'C1主波长多点吸光度',
              value:this.detailData.c1_abs_info.abs650?this.detailData.c1_abs_info.abs650.join(','):'',
              width: '75%',
              rightBorder: false,
            },
            {
              name: 'C1副波长多点吸光度',
              value:this.detailData.c1_abs_info.abs578?this.detailData.c1_abs_info.abs578.join(','):'',
              width: '100%',
              rightBorder: false,
            },
          ];
          let C2 = [
            { 
              name: 'C2_均值',
              value: qc_data.target_c2.toFixed(2),
              width: '33%',
            },
            {
              name: 'C2_SD',
              value: qc_data.sd_c2.toFixed(2),
              width: '33%',
            },
            {
              name: 'C2_CV',
              value: (qc_data.cv_c2*100).toFixed(2)+'%',
              width: '33%',
              rightBorder: false,
            },
            {
              name: 'C2质控结果',
              value: this.detailData.is_c2_lc?'失控':'在控',
              width: '33%',
            },
            {
              name: 'C2违背规则',
              value: this.detailData.c2_rule,
              width: '66%',
              rightBorder: false,
            },
            {
              name: 'C2_差值吸光度',
              value: this.detailData.c2_abs_info.abs_diff,
              width: '25%',
            },
            {
              name: 'C2主波长多点吸光度',
              value:this.detailData.c2_abs_info.abs650?this.detailData.c2_abs_info.abs650.join(','):'',
              width: '75%',
              rightBorder: false,
            },
            {
              name: 'C2副波长多点吸光度',
              value:this.detailData.c2_abs_info.abs578?this.detailData.c2_abs_info.abs578.join(','):'',
              width: '100%',
              rightBorder: false,
            },
          ]
          if(this.detailData.platform_unique == 'OTTOMAN-2020') {
            C1.push({
              name: 'C1第二主波长多点吸光度',
              value:this.detailData.c1_abs_info.abs2?this.detailData.c1_abs_info.abs2.join(','):'',
              width: '100%',
              rightBorder: false,
            });
            C2.push({
              name: 'C2第二主波长多点吸光度',
              value:this.detailData.c2_abs_info.abs2?this.detailData.c2_abs_info.abs2.join(','):'',
              width: '100%',
              rightBorder: false,
            });
          }
        this.dataListB = [
          {
            name: '方法学',
            value: this.detailData.method,
            rightBorder: false,
            width: '100%',
          },
          ...C1,...C2]

          // this.dataListB[0].value = this.detailData.method
          // this.dataListB[1].value = this.detailData.target_value
          // this.dataListB[2].value = this.detailData.sd
          // this.dataListB[3].value = this.detailData.cv
          // this.dataListB[4].value = '' //质控结果
          // this.dataListB[5].value = '' //判断规则
          // this.dataListB[6].value = '' //失控规则
          // this.dataListB[7].value = '' //失控原因
          // this.dataListB[8].value = '' //解决方案
          // this.dataListB[9].value = this.detailData.abs_diff //差值吸光度
          // this.dataListB[10].value = this.detailData.qcabs_data //主波长多点吸光度
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.bottomBtn {
  position: absolute;
  bottom: 0;
  width: px(1675);
  background: #fff;
  height: px(56);
  background: #ffffff;
  box-shadow: px(2) 0 px(4) 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 2;
  p {
    width: px(128);
    height: px(36);
    border-radius: px(5);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: px(18);
    cursor: pointer;
    color: #fff;
    background: #8591a0;
    margin-right: px(20);
  }
  
}
.printBox{
  margin-top: px(20);
}
</style>
